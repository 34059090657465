import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';

import {
  AlertsProvider,
  configureRaygun,
  MiSuiteJurisdiction,
  MiSuiteRole,
  ssoUtils,
  User,
  UserProvider
} from '@reasoncorp/kyber-js';

import packageJson from '../package.json';
import './index.scss';
import App from './App';
import config from './config';
import {ssoApi} from './api';

if (config.envName !== 'prod') {
  document.title = `${config.envName.toUpperCase()} - ${document.title}`;
}

const raygunOptions = {
  apiKey: process.env.REACT_APP_RAYGUN_API_KEY || 'apikeygoeshere',
  version: packageJson.version,
  tags: [config.envName]
};

// Remove this line if Raygun is not needed
configureRaygun(raygunOptions);

const setPermissions = (currentUser?: User) => ({
  isSsoAdmin: ssoUtils.hasJurisdictionAndRole(currentUser, MiSuiteJurisdiction.SSO_APP, MiSuiteRole.ADMIN)
});

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter>
    <UserProvider ssoApi={ssoApi} setPermissions={setPermissions}>
      <AlertsProvider>
        <App/>
      </AlertsProvider>
    </UserProvider>
  </BrowserRouter>
);